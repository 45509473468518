.owl-carousel .owl-wrapper:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel {
  -ms-touch-action: pan-y;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-wrapper-outer {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -o-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item {
  float: left;
}

.owl-controls .owl-page, .owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-controls {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: #0000;
}

.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-theme .owl-controls {
  text-align: center;
  margin-top: 10px;
}

.owl-theme .owl-controls .owl-buttons div {
  color: #fff;
  zoom: 1;
  filter: Alpha(Opacity= 50);
  opacity: .5;
  background: #869791;
  border-radius: 30px;
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  display: inline-block;
}

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity= 100);
  opacity: 1;
  text-decoration: none;
}

.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
}

.owl-theme .owl-controls .owl-page span {
  filter: Alpha(Opacity= 50);
  opacity: .5;
  background: #869791;
  border-radius: 20px;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  display: block;
}

.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity= 100);
  opacity: 1;
}

.owl-theme .owl-controls .owl-page span.owl-numbers {
  color: #fff;
  border-radius: 30px;
  width: auto;
  height: auto;
  padding: 2px 10px;
  font-size: 12px;
}

.owl-origin {
  perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
}

.owl-fade-out {
  z-index: 10;
  animation: .7s both fadeOut;
}

.owl-fade-in {
  animation: .7s both fadeIn;
}

.owl-backSlide-out {
  animation: 1s both backSlideOut;
}

.owl-backSlide-in {
  animation: 1s both backSlideIn;
}

.owl-goDown-out {
  animation: .7s both scaleToFade;
}

.owl-goDown-in {
  animation: .6s both goDown;
}

.owl-fadeUp-in {
  animation: .5s both scaleUpFrom;
}

.owl-fadeUp-out {
  animation: .5s both scaleUpTo;
}

@keyframes empty {
  0% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px)translateX(-200%);
  }

  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px)translateX(-200%);
  }
}

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px)translateX(-200%);
  }

  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px)translateX(-200%);
  }
}

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px);
  }

  75% {
    opacity: .5;
    transform: translateZ(-500px)translateX(-200%);
  }

  100% {
    opacity: .5;
    transform: translateZ(-500px)translateX(-200%);
  }
}

@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px)translateX(200%);
  }

  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0)translateX(0);
  }
}

@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-500px)translateX(200%);
  }

  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateZ(0)translateX(0);
  }
}

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-500px)translateX(200%);
  }

  75% {
    opacity: .5;
    transform: translateZ(-500px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)translateX(0);
  }
}

@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(.8);
  }
}

@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(.8);
  }
}

@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(.8);
  }
}

@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%);
  }
}

@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}

@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
}

@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}

@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
/*# sourceMappingURL=index.483fc346.css.map */
